<template>
  <div class="fluid" id="provisionList">
    <div>
      <div class="provision-list-div">
        <v-card elevation="0" class="px-3 py-2" style="width: 100%">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h4 class="indigo--text" style="font-weight: bold; margin: auto">
                KETENTUAN BPJS
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDataFromApi"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="provision-toolbar-1"
                  flat
                  style="
                    height: 60px;
                    border-radius: 10px 10px 0 0;
                    padding-top: 15px;
                  "
                >
                  <div class="d-flex align-content-start">
                    <div
                      style="margin-right: 15px; height: 45px"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        style="font-size: 12px"
                        @click="addProvision"
                        elevation="2"
                        tilerounded="5px"
                        color="primary"
                        class="white--text"
                        small
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="">
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>Tambah Ketentuan</span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                  </div>
                  <div style="width: 210px; height: 45px">
                    <v-autocomplete
                      v-model="paramAPI.company_id"
                      label="Company"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="companyWatcher"
                      :items="dropdown.company"
                      :disabled="isDisableCompanyDropdown"
                    ></v-autocomplete>
                  </div>
                  <div style="width: 210px; height: 45px; margin-left: 10px">
                    <v-autocomplete
                      v-model="paramAPI.type"
                      label="Tipe"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="typeWatcher"
                      :items="dropdown.type"
                    ></v-autocomplete>
                  </div>
                  <!-- <div style="width: 50%; height: 45px; margin-left: auto">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      @keyup.enter="searchEnter"
                      label="Search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                    ></v-text-field>
                  </div> -->
                </v-toolbar>
              </div>
            </v-col>

            <v-col cols="12" style="width: 100%">
              <v-data-table
                v-if="result.length > 0 || !skeleton_loading"
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                :page="paramAPI.page"
                @update:page="updatePage"
                :items-per-page="paramAPI.itemsPerPage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div
                    style="
                      font-size: 14px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                  </div>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      width: 120px;
                      display: flex;
                      flex-direction: row;
                      color: green;
                    "
                  >
                    {{ tipeBpjs(item.type) }}
                  </div>
                </template>
                <template v-slot:[`item.bpjs_template`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      width: 80px;
                      display: flex;
                      flex-direction: row;
                      color: green;
                    "
                  >
                    {{ item.bpjs_template }}
                  </div>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 200px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.company_name }}
                  </div>
                </template>
                <template v-slot:[`item.level`]="{ item }">
                  <div
                    @click.stop
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                      width: 400px;
                      height: 40px;
                      overflow: auto;
                      border: 1px solid black;
                    "
                  >
                    {{ item.level }}
                  </div>
                </template>
                <template v-slot:[`item.start_salary`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ priceConvert(item.start_salary) }}
                  </div>
                </template>
                <template v-slot:[`item.registered_salary`]="{ item }">
                  <div
                    style="
                      width: 100px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ priceConvert(item.registered_salary) }}
                  </div>
                </template>
                <template v-slot:[`item.end_salary`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ priceConvert(item.end_salary) }}
                  </div>
                </template>
                <template
                  v-slot:[`item.percentage_registered_salary`]="{ item }"
                >
                  <div
                    style="
                      padding: 0;
                      width: 90px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{
                      item.percentage_registered_salary !== null
                        ? item.percentage_registered_salary
                        : '-'
                    }}
                  </div>
                </template>
                <template
                  v-slot:[`item.calculation_registered_salary_method`]="{
                    item
                  }"
                >
                  <div
                    style="
                      width: 140px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{
                      getCalculationMethodName(
                        item.calculation_registered_salary_method
                      )
                    }}
                  </div>
                </template>
                <template v-slot:[`item.employee_level`]="{ item }">
                  <div @click.stop style="padding: 0; width: 410px">
                    <v-autocomplete
                      v-model="item.employee_level"
                      :items="dropdown.employee_level"
                      readonly
                      chips
                      multiple
                      small-chips
                      append-icon=""
                      item-text="name"
                      item-value="id"
                      return-id
                    />
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 80px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.createdAt) }}
                  </div>
                </template>
                <template v-slot:[`item.updatedAt`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 80px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.updatedAt) }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="rowClick(item)"
                        small
                        color="primary"
                        class="mr-2"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        color="error"
                        @click.stop="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-skeleton-loader
                v-else
                style="width: 100%"
                type="table-heading, table-tbody, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="privisionDialog" persistent max-width="500px">
      <v-card v-if="form !== null">
        <v-card-title>
          <span class="text-h5">Add/Edit Ketentuan BPJS</span>
        </v-card-title>
        <v-form ref="entryForm2" @submit.prevent="saveProvision">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Perusahaan
                  </p>
                  <v-autocomplete
                    v-model="form.company"
                    :items="dropdown.company"
                    :disabled="isDisableCompanyDropdown"
                    :rules="rules.companyRules"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    outlined
                    clearable
                    style="height: 45px"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Template
                  </p>
                  <v-autocomplete
                    v-model="form.bpjs_template"
                    :items="[
                      'template_1',
                      'default',
                      'template_2',
                      'template_3'
                    ]"
                    append-icon=""
                    outlined
                    clearable
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Tipe BPJS
                  </p>
                  <v-autocomplete
                    v-model="form.type"
                    :items="dropdown.type"
                    :rules="rules.typeRules"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    outlined
                    clearable
                    style="height: 45px"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Metode Kalkulasi
                  </p>
                  <v-autocomplete
                    v-model="form.calculation_registered_salary_method"
                    :items="dropdown.method"
                    :rules="rules.methodRules"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    outlined
                    clearable
                    style="height: 45px"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Gaji Minimal
                  </p>
                  <v-text-field
                    v-model="form.start_salary"
                    :rules="rules.startRules"
                    dense
                    outlined
                    type="text"
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Gaji Maximal
                  </p>
                  <v-text-field
                    v-model="form.end_salary"
                    :rules="rules.endRules"
                    dense
                    outlined
                    type="text"
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Gaji yang didaftarkan
                  </p>
                  <v-text-field
                    v-model="form.registered_salary"
                    :rules="rules.registeredSalaryRules"
                    :disabled="
                      form.calculation_registered_salary_method === null ||
                      form.calculation_registered_salary_method === 2 ||
                      form.calculation_registered_salary_method === 3
                    "
                    dense
                    outlined
                    type="text"
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Persentase gaji yang didaftarkan
                  </p>
                  <v-text-field
                    v-model="form.percentage_registered_salary"
                    :disabled="
                      form.calculation_registered_salary_method === null ||
                      form.calculation_registered_salary_method === 1 ||
                      form.calculation_registered_salary_method === 3
                    "
                    dense
                    outlined
                    type="text"
                    clearable
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Jabatan
                  </p>
                  <v-autocomplete
                    v-model="form.employee_level"
                    :items="dropdown.employee_level"
                    chips
                    multiple
                    small-chips
                    deletable-chips
                    append-icon=""
                    item-text="name"
                    item-value="id"
                    return-id
                    outlined
                    clearable
                    style="margin: 0"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeProvisionDialog">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations /*  mapActions */ } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  name: 'mastershift',
  data: () => ({
    editDialog: false,
    privisionDialog: false,
    eSanqua: buildType.apiURL('esanqua'),
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      // keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'date',
      sortType: 'desc',
      company_id: null,
      type: null
      // company_id: null,
      // department_id: null
    },
    totalData: 0,
    form: null,
    detail: null,
    isEdit: false,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tipe BPJS',
        value: 'type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Template',
        value: 'bpjs_template',
        align: 'left',
        sortable: false
      },
      {
        text: 'Metode Perhitungan',
        value: 'calculation_registered_salary_method',
        align: 'left',
        sortable: false
      },
      {
        text: 'Gaji Min',
        value: 'start_salary',
        align: 'left',
        sortable: false
      },
      {
        text: 'Gaji Max',
        value: 'end_salary',
        align: 'left',
        sortable: false
      },
      {
        text: 'Gaji Terdaftar',
        value: 'registered_salary',
        align: 'left',
        sortable: false
      },
      {
        text: 'Persentase dari gaji terdaftar',
        value: 'percentage_registered_salary',
        align: 'left',
        sortable: false
      },
      {
        text: 'Jabatan',
        value: 'employee_level',
        align: 'left',
        sortable: false
      },
      {
        text: 'Dibuat pada',
        value: 'createdAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Diedit pada',
        value: 'updatedAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    isDisableCompanyDropdown: false,
    rules: {
      companyRules: [],
      startRules: [],
      endRules: []
    },
    dropdown: {
      company: [],
      type: [
        {
          id: 'tk',
          name: 'TENAGA KERJA'
        },
        {
          id: 'jp',
          name: 'JAMINAN PENSIUN'
        },
        {
          id: 'k',
          name: 'KESEHATAN'
        }
      ],
      method: [
        {
          id: 1,
          name: 'NOMINAL TETAP'
        },
        {
          id: 2,
          name: 'PERSENTASE DARI THP'
        },
        {
          id: 3,
          name: 'Gapok + Tunjab'
        }
      ],
      employee_level: [],

      unwatchstartsalary: null,
      unwatchendsalary: null,
      unwatchregisteredsalary: null
    },
    skeleton_loading: true
  }),

  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.paramAPI.department_id = this.getUserProfile.employee.department_id
    if (this.getBpjsProvisionPageQuery !== null) {
      this.paramAPI = this.getBpjsProvisionPageQuery
    }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()
    }, 200)
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile', 'getBpjsProvisionPageQuery'])
  },
  watch: {
    'form.calculation_registered_salary_method'(val) {
      if (val !== null) {
        switch (val) {
          case 1:
            this.form.percentage_registered_salary = null
            break
          case 2:
            this.form.registered_salary = '0'
            break
          case 3:
            this.form.registered_salary = '0'
            this.form.percentage_registered_salary = null
            break
        }
      } else {
        this.form.registered_salary = '0'
        this.form.percentage_registered_salary = null
      }
    }
  },
  methods: {
    // ...mapActions(['dropdownPlant']),
    ...mapMutations(['setBpjsProvisionPageQuery']),
    startWatcher() {
      this.unwatchstartsalary = this.$watch('form.start_salary', (val) => {
        var removeChar = this.form.start_salary.replace(/[^0-9]/g, '') // This is to remove alphabets and special characters.

        var removeDot = removeChar.replace(/\./g, '') // This is to remove "DOT"
        this.form.start_salary = removeDot

        var formatedNumber = this.form.start_salary.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
        this.form.start_salary = formatedNumber
      })

      this.unwatchendsalary = this.$watch('form.end_salary', (val) => {
        var removeChar = this.form.end_salary.replace(/[^0-9]/g, '') // This is to remove alphabets and special characters.

        var removeDot = removeChar.replace(/\./g, '') // This is to remove "DOT"
        this.form.end_salary = removeDot

        var formatedNumber = this.form.end_salary.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
        this.form.end_salary = formatedNumber
      })

      this.unwatchregisteredsalary = this.$watch(
        'form.registered_salary',
        (val) => {
          var removeChar = this.form.registered_salary.replace(/[^0-9]/g, '') // This is to remove alphabets and special characters.

          var removeDot = removeChar.replace(/\./g, '') // This is to remove "DOT"
          this.form.registered_salary = removeDot

          var formatedNumber = this.form.registered_salary.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ','
          )
          this.form.registered_salary = formatedNumber
        }
      )
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
      this.getDropdownEmployeeLevel()
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    // searchEnter() {
    //   this.resetSomeParam()
    //   this.getDataFromApi()
    // },
    companyWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    typeWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },

    async getDropdownEmployeeLevel() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.employee_level = res.data.data)
          }
          return (this.dropdown.employee_level = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.employee_level = [])
        })
    },
    async dropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) => id === '1' || id === '39'
      )
      let xFlag = false
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            xFlag = true
          } else {
            this.dropdown.company = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          xFlag = true
        }
      } else {
        xFlag = true
      }

      if (xFlag) {
        if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
          this.isDisableCompanyDropdown = true
        } else {
          this.isDisableCompanyDropdown = false
        }
        axios
          .get(`${this.eSanqua}master/universal/plant/dropdown`)
          .then((res) => {
            this.dropdown.company = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      let url = `${this.hrsApi}master/universal/bpjsprovision/list?offset=${
        this.paramAPI.offset * this.paramAPI.limit
      }&limit=${this.paramAPI.limit}`
      const filter = []
      if (
        this.paramAPI.company_id !== undefined &&
        this.paramAPI.company_id !== null
      ) {
        filter.push(`{"company_id": ${this.paramAPI.company_id}}`)
      }
      if (this.paramAPI.type != undefined && this.paramAPI.type != null) {
        filter.push(`{"type": ${JSON.stringify(this.paramAPI.type)}}`)
      }
      url = url + `&filter=[${filter}]`
      this.setBpjsProvisionPageQuery(this.paramAPI)
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },

    rowClick(pItem) {
      console.log(pItem)

      this.form = {
        act: 'update',
        id: pItem.id,
        company: {
          id: pItem.company_id,
          name: pItem.company_name
        },
        start_salary: '0',
        end_salary: '0',
        type: pItem.type,
        registered_salary: '0',
        percentage_registered_salary: pItem.percentage_registered_salary,
        calculation_registered_salary_method:
          pItem.calculation_registered_salary_method,
        bpjs_template: pItem.bpjs_template
      }
      this.startWatcher()
      setTimeout(() => {
        this.form.start_salary = pItem.start_salary
        this.form.end_salary = pItem.end_salary
        this.form.registered_salary = pItem.registered_salary
      }, 50)
      setTimeout(() => {
        this.privisionDialog = !this.privisionDialog
      }, 300)
    },
    async deleteItem(pItem) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.hrsApi}master/universal/bpjsprovision/delete/${pItem.id}`
            )
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    addProvision() {
      this.form = {
        act: 'add',
        company: null,
        start_salary: '0',
        end_salary: '0',
        type: null,
        registered_salary: '0',
        percentage_registered_salary: null,
        calculation_registered_salary_method: null
      }
      setTimeout(() => {
        this.startWatcher()
        this.privisionDialog = !this.privisionDialog
      }, 300)
    },
    saveProvision() {
      this.rules.companyRules = [(v) => !!v || 'Company is required']
      this.rules.typeRules = [(v) => !!v || 'Type is required']
      this.rules.methodRules = [(v) => !!v || 'Method is required']
      // this.rules.startRules = [v => !!v || 'Start Time is required']
      // this.rules.endRules = [v => !!v || 'End Time is required']
      // this.rules.registeredSalaryRules = [v => !!v || 'Registered Salary is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm2.validate()) {
          const form = {
            name: '-',
            act: self.form.act,
            company_id: self.form.company.id,
            company_name: self.form.company.name,
            start_salary: self.form.start_salary,
            end_salary: self.form.end_salary,
            registered_salary: self.form.registered_salary,
            type: self.form.type,
            calculation_registered_salary_method:
              self.form.calculation_registered_salary_method,
            percentage_registered_salary:
              self.form.percentage_registered_salary,
            employee_level: self.form.employee_level,
            bpjs_template: self.form.bpjs_template
          }

          form.start_salary = Number(form.start_salary.replace(/,/g, ''))
          form.end_salary = Number(form.end_salary.replace(/,/g, ''))
          form.registered_salary = Number(
            form.registered_salary.replace(/,/g, '')
          )

          if (form.act === 'update') {
            Object.assign(form, { id: self.form.id })
          }
          console.log(form)
          self.submitProvision(form)
        }
      })
    },
    async submitProvision(form) {
      await axios
        .post(`${this.hrsApi}master/universal/bpjsprovision/save`, form)
        .then(async (res) => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            this.closeProvisionDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    closeProvisionDialog() {
      this.unwatchstartsalary()
      this.unwatchendsalary()
      this.unwatchregisteredsalary()
      setTimeout(() => {
        this.form = null
        this.privisionDialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    getCalculationMethodName(xVal) {
      let newVal = ''
      switch (xVal) {
        case 1:
          newVal = 'Nominal Tetap'
          break
        case 2:
          newVal = 'Persentase dari THP'
          break
        case 3:
          newVal = 'Gapok + Tunjab'
          break
        default:
          break
      }

      return newVal
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },
    tipeBpjs(v) {
      let text = ''
      switch (v) {
        case 'k':
          text = 'KESEHATAN'
          break
        case 'jp':
          text = 'JAMINAN PENSIUN'
          break
        case 'tk':
          text = 'TENAGA KERJA'
          break
      }

      return text
    }
  }
}
</script>
<style lang="scss">
#provisionList {
  position: relative;
  .provision-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .provision-toolbar-1 {
      .provision-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #provisionList {
    .provision-list-div {
      .provision-toolbar-1 {
        .provision-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
